import React from "react"

import "./performance-library-icon.css"

const PerformanceLibraryIcon = () => (
  <div className="icon performance-library-icon">
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 144.05 137"
      style={{ enableBackground: "new 0 0 144.05 137" }}
    >
      <polygon
        class="temporary-dwellings-fill"
        points="72.03,0 54.02,50.95 0,52.33 42.89,85.2 27.51,137 72.03,106.37 116.54,137 101.16,85.2 144.05,52.33 
	90.03,50.95 "
      />
    </svg>
    <div>Performance Library</div>
  </div>
)

export default PerformanceLibraryIcon
